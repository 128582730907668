<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2022-10-11 10:34:40
 * @LastEditors: Seven
 * @LastEditTime: 2022-10-11 11:47:56
-->
<template>
  <span>
    <el-button :disabled="disabled" class="hq-button" @click="modalShow = true" type="primary">变更{{ title }}</el-button>
    <HqModal :visible="modalShow" :title="'变更' + title" width="400" @on-cancel="cancel">
      <el-form ref="form" :model="form" :rules="formRules">
        <el-row :gutter="10">
          <!-- <el-col :span="24">
            <el-form-item prop="changeResson">
              <v-textarea
                v-model="form.changeResson"
                outlined
                :height="80"
                placeholder="输入变更理由，200字内"
                name="input-7-4"
                label="变更理由"
              ></v-textarea>
            </el-form-item>
          </el-col> -->
          <el-col :span="24" style="margin-top: 10px">
            <el-form-item>
              <v-text-field
                :value="currCol.teacherName || '--'"
                :label="'原' + title + '老师'"
                hide-details="auto"
                outlined
                readonly
                :dense="true"
                :height="32"
              ></v-text-field>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item>
              <v-autocomplete
                v-model="form.teacherPk"
                :items="teacherList"
                :menu-props="{ bottom: true, offsetY: true }"
                label="变更为"
                item-text="userName"
                item-value="ncUserPk"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
                clear-icon="$clear"
                hide-details="auto"
                no-data-text="暂无数据"
              ></v-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="save" type="primary">确认</el-button>
      </div>
    </HqModal>
  </span>
</template>
<script>
import { doUpdateTeacher } from "api/public"
export default {
  name: "ncchangeModal",
  props: {
    title: {
      type: String,
    },
    teacherList: {
      type: Array,
    },
    currCol: {
      type: Object,
    },
    method: {
      type: Function,
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },  
  data() {
    return{
      form: {
        teacherPk: "",
      },
      formRules: {
        teacherPk: [
          {required: true, message: "请选择变更人"}
        ]
      },
      modalShow: false,
    }
  },
  methods: {
    save() {
      this.$refs['form'].validate((valid) => {
        if(valid) {
          doUpdateTeacher({orderPk: this.currCol.orderPk, teacherPk: this.form.teacherPk}).then(data => {
            if(data.code === 0) {
              this.$message.success(data.msg)  
              this.method()
            } else {
              this.$message.error(data.msg)
            }
            this.cancel()  
          })
        }
      })
    },
    cancel() {
      this.modalShow = false
      this.form = {
        changeResson: "",
        changeAfterNcId: null
      }
    }
  }
}
</script>
